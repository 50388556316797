// src/components/ListInvoices.js
import React, { useEffect, useState } from 'react';
import './ListInvoices.css';
import Header from './Header'; // Uvezi komponentu Header
import { generateInvoiceQuotationImage, generateInvoiceQuotationNumber } from './utils/invoiceUtils'; // Ažuriraj putanju prema tvojoj strukturi
import InvoicePreviewModal from './InvoicePreviewModal';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './utils/axiosInstance'
import { FaEnvelope } from 'react-icons/fa'; // Import email icon
import { Tooltip } from 'react-tooltip'; // Import Tooltip


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ListInvoices = ({ selectedName, onLogout }) => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchInvoices = async () => {
    try {
  
      const response = await axiosInstance.get(
        `${API_URL}/invoices`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      setInvoices(response.data.reverse());
    } catch (error) {
      console.error('Error fetching invoices:', error);
      alert('Došlo je do greške u dohvaćanju računa');

            // Provjeri je li greška tipa 401
            if (error.response && error.response.status === 401) {
              navigate('/'); // Redirekt na login
              onLogout();    // Očisti podatke o prijavi

            }

    }
  };
  

  useEffect(() => {
    fetchInvoices(); // Initial fetch when component mounts
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const handleChangeUser = () => {
    selectedName = '';
    navigate('/names');
  };

  const handleCancelInvoice = async (invoice) => {
    const canceledInvoiceData = {
      invoiceNumber: await generateInvoiceQuotationNumber(true),
      operator: invoice.operator,
      date: new Date().toISOString(),
      customer: invoice.customer,
      services: invoice.services.map(service => ({
        ...service,
        quantity: service.quantity * -1,
      })),
      totalAmount: invoice.totalAmount * -1,
      canceledInvoice: invoice._id,
      quotationReference: invoice.quotationReference,
      canceledBy: null,
      emailHistory: [], // Ensure emailHistory is empty for the canceled invoice,
      isPaid: true
    };
  
    if (canceledInvoiceData) {
      try {
        // Prvi zahtjev za kreiranje računa
        const response = await axiosInstance.post(
          `${API_URL}/invoices`,
          canceledInvoiceData,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json', // Osiguranje ispravnog tipa sadržaja
            }
          }
        );
  
        const newInvoiceId = response.data._id;
  
        // Drugi zahtjev za storniranje
        await axiosInstance.put(
          `${API_URL}/invoices/cancel/${invoice._id}`,
          { canceledBy: newInvoiceId },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json', // Osiguranje ispravnog tipa sadržaja
            }
          }
        );
  
      } catch {
        console.error("Greška prilikom storniranja!");
        alert('Došlo je do greške prilikom storniranja računa');
  
      } finally {
        openInvoicePreview(canceledInvoiceData);
      }
    }
  };
  

  const getOperatorFullName = (selectedName) => {
    const operatorNames = {
      Marija: 'Marija Jozić',
      Dubravka: 'Dubravka Pešorda',
      Josipa: 'Josipa Piuk',
      Lucija: 'Lucija Vojnović',
      Katarina: 'Katarina Andrijašević',
      Mirna: 'Mirna Bašić-Šiško',
      Jurica: 'Jurica Đerek'
    };

    return operatorNames[selectedName] || selectedName;
  };

  const openInvoicePreview = async (invoice) => {
    const invoiceYear = new Date(invoice.date).getFullYear(); // Get the invoice year
    const imageUrl = await generateInvoiceQuotationImage(invoice.invoiceNumber, true, invoiceYear); // Generiraj URL slike
    setSelectedInvoice({ ...invoice, imageUrl }); // Dodaj URL slike u odabrani račun
    setIsModalOpen(true); // Otvori modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInvoice(null);
    fetchInvoices();
  };

  const handlePaymentStatusToggle = async (invoice) => {
    if (selectedName !== 'Jurica') {
      return;
    }
    try {
      const updatedInvoice = await axiosInstance.put(
        `${API_URL}/invoices/update-payment-status/${invoice._id}`,
        { isPaid: !invoice.isPaid },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      setInvoices((prevInvoices) =>
        prevInvoices.map((inv) =>
          inv._id === invoice._id ? { ...inv, isPaid: !invoice.isPaid } : inv
        )
      );
    } catch (error) {
      console.error('Error updating payment status:', error);
      alert('Došlo je do greške prilikom ažuriranja statusa plaćanja');
    }
  };

  return (
    <div>
      <Header 
        selectedName={selectedName} 
        handleLogout={handleLogout} 
        handleChangeUser={handleChangeUser}
      />
      <div className='table-container'>
        <table className='invoice-table'>
          <thead>
            <tr>
              <th className='invoice-number'>Račun Broj</th>
              <th className='operator'>Operater</th>
              <th className='client'>Klijent</th>
              <th className='date'>Datum</th>
              <th className='total'>Ukupno</th>
              <th className='action'>Akcija</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice._id}>
                <td className='invoice-number'>{invoice.invoiceNumber}</td>
                <td className='operator'>{getOperatorFullName(invoice.operator)}</td>
                <td className='client'>
                  {invoice.customer.name}
                  {invoice.emailHistory.length > 0 && (
                    <>
                      <FaEnvelope 
                        className='email-icon' 
                        data-tooltip-id={`emailHistory-${invoice._id}`} 
                      />
                      <Tooltip id={`emailHistory-${invoice._id}`} place="top" effect="solid">
                        {invoice.emailHistory.map((entry, index) => (
                          <div key={index}>
                            <p>{new Date(entry.sentAt).toLocaleString()} {entry.email}</p>
                          </div>
                        ))}
                      </Tooltip>
                    </>
                  )}
                </td>
                <td className='date'>
                  {new Date(invoice.date).toLocaleDateString('hr-HR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                  <br />
                  {new Date(invoice.date).toLocaleTimeString()} 
                </td>
                <td className='total'>
                  {invoice.totalAmount.toFixed(2)} €
                  <span
                    className={invoice.isPaid ? 'paid' : 'unpaid'}
                    onClick={() => handlePaymentStatusToggle(invoice)}
                    style={{ cursor: selectedName === 'Jurica' ? 'pointer' : 'default' }}
                    title={invoice.isPaid ? 'Plaćen' : 'Neplaćen'}
                  >
                    {invoice.isPaid ? ' ✔️' : ' ❌'}
                  </span>
                </td>
                <td className='action'>
                  <button 
                    className='preview-button' 
                    onClick={() => openInvoicePreview(invoice)}>Pregled
                  </button>
                  <button 
                    className='cancel-button' 
                    onClick={() => handleCancelInvoice(invoice)}
                    disabled={invoice.canceledBy || invoice.canceledInvoice} 
                    title={
                      invoice.canceledBy?.invoiceNumber
                      ? `Ovaj račun je storniran računom ${invoice.canceledBy.invoiceNumber}-P1-1`
                      : invoice.canceledInvoice?.invoiceNumber
                      ? `Ovaj račun je storno računa ${invoice.canceledInvoice.invoiceNumber}-P1-1`
                      : ""
                    }
                  >
                    Storno
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal za pregled računa */}
      {selectedInvoice && (
        <InvoicePreviewModal 
          isOpen={isModalOpen} 
          onRequestClose={closeModal} 
          invoiceImageUrl={selectedInvoice.imageUrl} 
          invoiceData={selectedInvoice}
        />
      )}
    </div>
  );
};

export default ListInvoices;
