import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import Header from './Header'; // Uvezi komponentu Header
import InvoicePreviewModal from './InvoicePreviewModal';
import { generateInvoiceQuotationImage, generateInvoiceQuotationNumber, nameSurnameMap,
  calculateTotalAmount, getInvoiceQuotationData
 } from './utils/invoiceUtils'
 import axiosInstance from './utils/axiosInstance';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';



const Profile = ({ selectedName, onLogout }) => {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('existing');
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({ name: '', address: '', oib: '', email: '' });
  const [selectedCustomer, setSelectedCustomer] = useState(null); // Stanje za odabranog kupca
  // Stanje za pretragu
  const [searchTerm, setSearchTerm] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);  //preview računa
  const [invoiceImageUrl, setInvoiceImageUrl] = useState('');

      // Priprema podataka za novi račun
      // Kreirajte mapu s imenima i prezimenima


  const navigate = useNavigate();

  const openPreviewModal = () => {
    setIsPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
    setInvoiceImageUrl('');
  };

  const handleLogout = () => {
    onLogout();
    navigate('/'); // Redirect to Login after logout
  };

  const handleChangeUser = () => {
    navigate('/names'); // Preusmjeri na stranicu s imenima
  };

  const selectCustomer = (client) => {
    setSelectedCustomer(client); // Postavi odabranog kupca
    setShowModal(false);
  };

  

  // Filtrirani kupci na osnovu pretrage
  const filteredClients = searchTerm 
  ? clients.filter(client =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase()) // Pretražuje po imenu
    )
  : clients; // Ako nema pretrage, prikaži sve kupce

  // Dohvati usluge
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${API_URL}/api/services`, {
          method: 'GET',
          credentials: 'include', // Omogućava slanje kolačića
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Unauthorized');
        }
        const data = await response.json();
        setServices(data);

      } catch (error) {
        console.error('Error fetching services:', error);
        alert('Došlo je do greške u dohvaćanju usluga');


      }
    };

    fetchServices();
  }, []);

  // Dohvati kupce kada se modal otvori
  useEffect(() => {
    if (showModal && activeTab === 'existing') {
      const fetchClients = async () => {
        try {
          const response = await fetch(`${API_URL}/api/clients`,{
            method: 'GET',
            credentials: 'include', // Omogućava slanje kolačića
            headers: {
              'Content-Type': 'application/json',
            },
          }); 
          if (!response.ok) {
            throw new Error('Unauthorized');
          }
          const data = await response.json();
          setClients(data);
        } catch (error) {
          console.error('Error fetching clients:', error);
          alert('Došlo je do greške u dohvaćanju kupaca');

        }
      };

      fetchClients();
    }
  }, [showModal, activeTab]);

  const handleServiceClick = (service) => {
    setSelectedServices((prevSelected) => {
      const existingService = prevSelected.find(s => s._id === service._id);
      if (existingService) {
        // Ako usluga već postoji, povećaj količinu za 1
        return prevSelected.map(s => 
          s._id === service._id ? { ...s, quantity: s.quantity + 1 } : s
        );
      } else {
        // Ako ne postoji, dodaj novu uslugu s količinom 1
        return [...prevSelected, { ...service, quantity: 1 }];
      }
    });
  };

  const handleAdjustQuantity = (serviceId, adjustment) => {
    setSelectedServices((prevSelected) => {
      return prevSelected.map(service => {
        if (service._id === serviceId) {
          const newQuantity = service.quantity + adjustment;
          return { ...service, quantity: newQuantity > 0 ? newQuantity : 1 }; // Osiguraj da količina ne bude manja od 1
        }
        return service;
      });
    });
  };

  // Izračunaj ukupnu cijenu
  const calculateTotalPrice = () => {
    return selectedServices.reduce((total, service) => {
      return total + (service.price * service.quantity);
    }, 0);
  };

  // Filter services to only show those with isEducation is false or does not exist and position between 1 and 9
  const filteredServices = services.filter(service => 
    (service.isEducation === false || service.isEducation === undefined) &&
    service.position >= 1 && service.position <= 9
  );


const handleClickCreateInvoiceQuotation = async (isInvoice) => {
  const invoiceNumber = await handleCreateInvoiceQuotation(isInvoice); // Stvaranje računa/ponude
  if (invoiceNumber) {
    const currentYear = new Date().getFullYear(); // Get the current year
    const imageUrl = await generateInvoiceQuotationImage(invoiceNumber, isInvoice, currentYear); // Generiraj sliku računa
    if (imageUrl) {
      setInvoiceData(await getInvoiceQuotationData(invoiceNumber, isInvoice)); // spremi podatke o računu
      setInvoiceImageUrl(imageUrl); // Postavi URL slike
      openPreviewModal(); // Otvori modal
    }
  }
};


  const handleCreateInvoiceQuotation = async (isInvoice) => {
    try {
      const newInvoiceNumber = await generateInvoiceQuotationNumber(isInvoice);
      const fullName = nameSurnameMap.has(selectedName) 
      ? `${selectedName} ${nameSurnameMap.get(selectedName)}` 
      : selectedName;
      const invoiceData = {
        [isInvoice ? 'invoiceNumber' : 'quotationNumber']: newInvoiceNumber,
        operator: fullName, // Ime operatera
        date: new Date(), // Datum i vrijeme
        customer: selectedCustomer._id, // ID odabranog kupca
        services: selectedServices.map(service => ({
          serviceId: service._id, // ID usluge
          quantity: service.quantity, // Količina
          price: service.price, // Cijena
          unit: service.unit // Jedinica
        })),
        totalAmount: calculateTotalAmount(selectedServices) // Izračunaj ukupni iznos
      };

      // Spremi račun u bazu
      const response = await axiosInstance.post(
        `${API_URL}/${isInvoice ? 'invoices' : 'quotations'}`,
        invoiceData,
        {
          withCredentials: true, // Omogućava slanje kolačića
          headers: {
            'Content-Type': 'application/json', // Osiguranje ispravnog tipa sadržaja
          },
        }
      );
      if (response.status === 201) {
        setInvoiceData(invoiceData);
        setSelectedCustomer(null);
        setSelectedServices([]);
        return newInvoiceNumber; // Vraća broj novog računa
      }else {
        throw new Error('Došlo je do greške prilikom kreiranja računa');
      }
    } catch (error) {
      console.error('Došlo je do greške prilikom kreiranja računa:', error);
      alert('Došlo je do greške prilikom kreiranja računa');

      return null; // Vraća null u slučaju greške
    }
  };

    // Funkcija za brisanje usluge
    const handleDeleteService = (id) => {
      setSelectedServices((prevSelected) => 
        prevSelected.filter((service) => service._id !== id)
      );
    };


  // Funkcija za prikaz modala
  const openModal = () => {
    setShowModal(true);
  };

  // Funkcija za zatvaranje modala
  const closeModal = () => {
    setShowModal(false);
  };

  // Funkcija za dodavanje novog kupca
  const handleAddClient = async (e) => {
    e.preventDefault(); // Spriječi ponovno učitavanje stranice

    try {
      const response = await fetch(`${API_URL}/api/clients`, {
        method: 'POST',
        credentials: 'include', // Omogućava slanje kolačića
        headers: {
          'Content-Type': 'application/json',        },
        body: JSON.stringify(newClient), // Pošalji nove podatke kupca
      });
  
      if (response.ok) {
        const addedClient = await response.json(); // Preuzmi odgovor koji sadrži novi klijent s `_id`
        setSelectedCustomer(addedClient); // Postavi selectedCustomer s podacima klijenta iz baze, uključujući `_id`
        
        // Resetiraj formu i zatvori modal
        setNewClient({ name: '', address: '', oib: '', email: '' });
        closeModal();
      } else {
        console.error('Failed to add client');
      }
    } catch (error) {
      console.error('Error adding client:', error);
      alert('Došlo je do greške u dodavanju kupca');

    }
  };


  return (
    <div className="profile-container">
      <Header 
        selectedName={selectedName} 
        handleChangeUser={handleChangeUser} 
        handleLogout={handleLogout} 
      />

      {/* Usluge */}
      <div className="services-container">
        <div className="services-grid">
          {filteredServices.map((service) => (
            <div 
              className="service-box" 
              key={service._id} 
              onClick={() => handleServiceClick(service)}
            >
              <h4>{service.name}</h4>
              <p>{service.price} €</p>
            </div>
          ))}
        </div>
        <div className="placeholder">
          <table className="services-table">
            <thead>
              <tr>
                <th>Naziv stavke</th>
                <th>Količina</th>
                <th>Cijena</th>
                <th>Izbriši</th>{/* Nova kolona za akcije */}
              </tr>
            </thead>
            <tbody>
              {selectedServices.map((service, index) => (
                <tr key={index}>
                  <td>{service.name}</td>
                  <td>
                    <span 
                      className="adjust-icon" 
                      onClick={() => handleAdjustQuantity(service._id, -1)}
                    >
                      -
                    </span>
                    {service.quantity}
                    <span 
                      className="adjust-icon" 
                      onClick={() => handleAdjustQuantity(service._id, 1)}
                    >
                      +
                    </span>
                  </td>
                  <td>{service.price} €</td>
                  <td>
                    <span 
                      className="delete-icon" 
                      onClick={() => handleDeleteService(service._id)}
                      style={{ color: 'red', cursor: 'pointer' }}
                    >
                      X
                    </span>
                  </td>
                </tr>
              ))}
              {/* Redak za ukupno */}
              <tr>
                <td colSpan="3" style={{ textAlign: 'right' }}>Ukupno:</td>
                <td>{calculateTotalPrice()} €</td> {/* Ostavimo akciju praznu */}
              </tr>
            </tbody>
          </table>
          
          {/* Link za Dodaj kupca i gumb za Kreiraj račun */}
          <div className="footer-actions">
            {calculateTotalPrice() > 0 && (
              <>
                <button onClick={openModal} className="add-customer-button">
                  {selectedCustomer ? "Promijeni " : "Dodaj "}kupca
                </button>
                <button onClick={() => handleClickCreateInvoiceQuotation(true)} className="create-invoice-button" disabled={!selectedCustomer}
                  title={!selectedCustomer ? "Najprije odaberi kupca" : ""}>
                  Izradi račun
                </button>
                <button onClick={() => handleClickCreateInvoiceQuotation(false)} className="create-quotation-button" disabled={!selectedCustomer}
                  title={!selectedCustomer ? "Najprije odaberi kupca" : ""}>
                  Izradi ponudu
                </button>
              </>
            )}
            <InvoicePreviewModal
            isOpen={isPreviewModalOpen}
            onRequestClose={closePreviewModal}
            invoiceImageUrl={invoiceImageUrl}
            invoiceData = {invoiceData}
          />
          </div>
          {selectedCustomer && (
          <h4>Odabrani kupac:</h4>)}
          {selectedCustomer && (
            <table className='selected-customer-table'>
              <thead>
                <tr>
                  <th>Naziv</th>
                  <th>Adresa</th>
                  <th>OIB</th>
                  <th>E-mail</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedCustomer.name}</td>
                  <td>{selectedCustomer.address}</td>
                  <td>{selectedCustomer.OIB}</td>
                  <td>{selectedCustomer.email}</td>
                </tr>
              </tbody>
            </table>)}
             {/* Red za crveni gumb */}
               {/* Gumb se prikazuje samo ako postoji selectedCustomer */}
                {selectedCustomer && (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                  onClick={() => setSelectedCustomer(null)}
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    borderRadius: '5px',
                  }}
                >
                  Ukloni kupca
                </button>
              </div>
               )}
            
          

        </div>
      </div>

      {/* Modal prozor */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
          <button onClick={closeModal} className="close-modal-button">Zatvori</button>

            <div className="tab-buttons">
              <button 
                className={activeTab === 'existing' ? 'active-tab' : 'inactive-tab'} 
                onClick={() => setActiveTab('existing')}
              >
                Postojeći kupac
              </button>
              <button 
                className={activeTab === 'new' ? 'active-tab' : 'inactive-tab'} 
                onClick={() => setActiveTab('new')}
              >
                Novi kupac
              </button>
            </div>

            <div className="tab-content">
              {activeTab === 'existing' ? (
                <div>
                  
                  
                    <div>
                      {/* Polje za pretragu */}
                      <input
                        type="text"
                        placeholder="Pretraži po imenu..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Ažuriraj searchTerm
                        className="search-input"
                      />
                  {/* Tablica postojećih kupaca */}
                  <table className="clients-table">
                    <thead>
                      <tr>
                        <th>Ime i prezime/Naziv</th>
                        <th>Adresa</th>
                        <th>OIB</th>
                        <th>Email</th>
                        <th>Akcija</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredClients.map((client, index) => (
                        <tr key={index}>
                          <td>{client.name}</td>
                          <td>{client.address}</td>
                          <td>{client.OIB}</td>
                          <td>{client.email}</td>
                          <td>
                            <button 
                              className="select-customer-button" 
                              onClick={() => selectCustomer(client)} // Funkcija za odabir kupca
                            >
                              Odaberi
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              


                </div>
              ) : (
                <form onSubmit={handleAddClient} className = "client-form">
                  <h4>Dodaj novog kupca:</h4>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">Ime i prezime/Naziv:</label>
                    <input
                      type="text"
                      id="name"
                      className="form-input"
                      value={newClient.name}
                      onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address" className="form-label">Adresa:</label>
                    <input
                      type="text"
                      id="address"
                      className="form-input"
                      value={newClient.address}
                      onChange={(e) => setNewClient({ ...newClient, address: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="oib" className="form-label">OIB:</label>
                    <input
                      type="text"
                      id="oib"
                      className="form-input"
                      value={newClient.OIB}
                      onChange={(e) => setNewClient({ ...newClient, OIB: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                      type="email"
                      id="email"
                      className="form-input"
                      value={newClient.email}
                      onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
                    />
                  </div>
                  <button type="submit" className="add-client-button">{selectedCustomer ? "Promijeni kupca":"Dodaj kupca"}</button>
                </form>

              )}
              <button onClick={closeModal} className="close-modal-button">Zatvori</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;