// src/utils/invoiceUtils.js
import axiosInstance from './axiosInstance';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const nameSurnameMap = new Map([
  ['Dubravka', 'Pešorda'],
  ['Jurica', 'Đerek'],
  ['Marija', 'Jozić'],
  ['Katarina', 'Andrijašević'],
  ['Lucija', 'Vojnović'],
  ['Mirna', 'Bašić-Šiško'],
  ['Josipa','Piuk']
  
]);

export 
const calculateTotalAmount = (services) => {
  return services.reduce((total, service) => {
    return total + (service.price * service.quantity); // Izračunaj ukupni iznos
  }, 0);
};


export const generateInvoiceQuotationImage = async (invoiceNumber, isInvoice, invoiceYear) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/${isInvoice ? 'invoices' : 'quotations'}/generate-image/${invoiceYear}/${invoiceNumber}`, {
      responseType: 'blob',
      withCredentials: true,
    });

    if (response.status !== 200) { // Check the response status code
      throw new Error(`Greška: ${response.statusText}`); // Throw an error if not OK
    }
    const url = URL.createObjectURL(response.data);
    return url; // Return the URL for the invoice image
  } catch (error) {
    console.error('Greška pri dobivanju slike računa:', error);
    alert('Došlo je do greške pri dohvaćanju računa');

    return '';
  }
};

export const generateInvoiceQuotationNumber = async (isInvoice) => {
  try {
    const currentYear = new Date().getFullYear();

    const response = await axiosInstance.get(
      `${API_URL}/${isInvoice ? 'invoices' : 'quotations'}/count?year=${currentYear}`,
      {
       withCredentials: true,
      }
    );

    if (!response.status === 200) { // Provjerite status kôd odgovora
      throw new Error(`Greška: ${response.statusText}`);
    }

    return response.data.count + 1; // Dodajemo 1 na broj izdanih računa
  } catch (error) {
    console.error('Greška pri generiranju broja računa/ponude:', error);
    alert('Došlo je do greške pri generiranju računa/ponude');

    return null; // Vratite null ili odgovarajuću zamjensku vrijednost u slučaju greške
  }
};


export const getInvoiceQuotationData = async (invoiceNumber, isInvoice) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/${isInvoice ? 'invoices' : 'quotations'}/no/${invoiceNumber}`,
      {
        withCredentials: true,
      }
    );

    if (!response.status === 200) { // Provjera uspješnosti odgovora
      throw new Error(`Greška: ${response.statusText}`);
    }

    return response.data; // Vraća podatke o računu
  } catch (error) {
    console.error('Greška pri dohvatu podataka o računu:', error);
    alert('Došlo je do greške u dohvaćanju podataka o računu');

    return null; // Vraća null ako dođe do greške
  }
};

